import { defineStore } from 'pinia';
import VehicleDetailService from '@/services/monitoring/detail/detail-vehicle.service';
import UserService from '@/services/user/user.service';
import { CONST } from '@/constants';
import LoginService from '@/services/account/login.service';
import _ from 'lodash';
import { useStore } from '@/store';

const ASC = true;
const vehicleDetailService = new VehicleDetailService();
const userService = new UserService();
const loginService = new LoginService();
const MAP_TYPES = [
  'roadmap',
  'hybrid',
  'terrain',
  'satellite'
];

export interface VehicleStateStorable {
  notificationList: Array<any>;
  notificationSelected: object;
  vehicleList: Array<any>;
  vehicleSelected: any;
  listRoad: Array<any>;
  activeBus: any;
  busStopSelected: any;
  tripActive: any;
  busInfo: any;
  listOffices: any;
  mapType: any;
  mapTypeId: any;
  mapZoomLever: any;
  isTrafficJam: boolean;
  isNightMap: boolean;
  noBackGround: boolean;
  filtered: {
    office: string,
    role: string,
    display: string
  };
  sortStatus: {
    prop: string,
    direction: boolean
  };
  mapStatus: {
    mapId: string,
    mapKey: string,
    center: {
      lat: number,
      lng: number,
    },
    zoom: number,
    reload: boolean
  }
  offServiceShow: boolean;
  busStopShow: boolean;
  detailBusStopShow: boolean;
  iconNameShow: boolean;
  officeUser: string;
  officeUserId: string;
  listRoutes: any;
  listVehicleTemp: any;
  filterStatus: boolean;
  filteredRoutes: any;
  filteredBusStops: any;
}

export const defaultVehicleState: VehicleStateStorable = {
  notificationList: [],
  vehicleList: [],
  listRoad: [],
  listOffices: [],
  notificationSelected: {},
  mapType: '',
  mapTypeId: 0,
  vehicleSelected: null,
  busStopSelected: null,
  activeBus: null,
  isTrafficJam: false,
  noBackGround: false,
  tripActive: {},
  busInfo: {},
  isNightMap: false,
  mapZoomLever: null,
  filtered: {
    office: '',
    role: '',
    display: ''
  },
  sortStatus: {
    prop: 'vehicle_id',
    direction: ASC
  },
  mapStatus: {
    mapId: DEFAULT_MAP_ID,
    mapKey: new Date().getTime().toString(),
    center: CONST.MAP.POSITION_CENTER_DEFAULT,
    zoom: CONST.MAP.ZOOM_DEFAULT,
    reload: false
  },
  offServiceShow: false,
  busStopShow: false,
  detailBusStopShow: false,
  iconNameShow: false,
  officeUser: '',
  officeUserId: '',
  listRoutes: [],
  listVehicleTemp: [],
  filterStatus: false,
  filteredRoutes: [],
  filteredBusStops: []
}

export const useVehicleStore = defineStore('vehicleStore', {
  state: (): VehicleStateStorable => ({ ...defaultVehicleState }),

  actions: {
    resetSortStatus() {
      this.sortStatus = { ...defaultVehicleState.sortStatus };
    },
    sort: function (prop) {
      this.sortStatus.direction = this.sortStatus.prop === prop ? !this.sortStatus.direction : ASC;
      this.sortStatus.prop = prop;
    },
    setActiveBus: function (activeBus) {
      this.activeBus = activeBus;
    },
    setNoBackGround: function (noBackGround) {
      this.noBackGround = noBackGround;
    },
    setMapZoomLever: function (mapZoomLever) {
      this.mapZoomLever = mapZoomLever;
    },
    setOffServiceShow: function (offServiceShow) {
      this.offServiceShow = offServiceShow;
    },
    setShowBusStop: function (busStopShow) {
      this.busStopShow = busStopShow;
    },
    setShowDetailBusStop: function (detailBusStopShow) {
      this.detailBusStopShow = detailBusStopShow;
    },
    setShowIconName: function (iconNameShow) {
      this.iconNameShow = iconNameShow;
    },
    async updateVehicleList(officeId, noBackGround) {
      await vehicleDetailService.getVehiclesList(officeId, noBackGround).then(res => {
        if (res.data) {
          this.listVehicleTemp = res.data
          if (this.filterStatus) {
            let filtered = res.data.filter(vehicle => {
              return this.filteredRoutes.find(route => route.jp_pattern_id === vehicle?.jp_pattern_active)
            });
            if (this.vehicleSelected && filtered.findIndex(record => record.vehicle_id === this.vehicleSelected.vehicle_id) === -1 && this.vehicleSelected.trip_active) {
              const recordVehicle = res.data.find(record => record.vehicle_id === this.vehicleSelected.vehicle_id);
              recordVehicle['trip_active'] = this.vehicleSelected['trip_active'];
              filtered.push(recordVehicle);
            }
            this.vehicleList = filtered
          } else {
            this.filteredBusStops = [];
            this.vehicleList = res.data;
          }
          this.vehicleList.forEach(vehicle => {
            if (vehicle.status_vehicle === CONST.STATUS_BUS.LOST_CONNECTION || vehicle.status_vehicle === CONST.STATUS_BUS.OFFLINE) {
              vehicle['statusVehicleSort'] = '1'
            } else if (vehicle.status_vehicle === CONST.STATUS_BUS.POWER_OFF) {
              vehicle['statusVehicleSort'] = '2'
            } else if (vehicle.status_vehicle === CONST.STATUS_BUS.OFF_SERVICE) {
              vehicle['statusVehicleSort'] = '3'
            } else {
              vehicle['statusVehicleSort'] = '0'
            }
            const delay = vehicle.time_delay;
            if(vehicle.status_vehicle === CONST.STATUS_BUS.OFFLINE) {
              vehicle['delayProcessed'] = CONST.TIME_BUS_OFFLINE;
            } else if(vehicle.status_vehicle !== CONST.STATUS_BUS.ON_SERVICE) {
              vehicle['delayProcessed'] = CONST.TIME_BUS_NOT_ON_SERVICE;
            } else if (!delay || delay === '0') {
              vehicle['delayProcessed'] = 0;
            } else if (delay.includes('-')) {
              if (delay.includes('min')) {
                vehicle['delayProcessed'] = -parseInt(delay.substring(1).slice(0, -3)) * 60;
              } else {
                vehicle['delayProcessed'] = -parseInt(delay.substring(1).slice(0, -3));
              }
            } else if (delay.includes('min')) {
              vehicle['delayProcessed'] = parseInt(delay.substring(1).slice(0, -3)) * 60;
            } else {
              vehicle['delayProcessed'] = null;
            }
            if (!vehicle.final_communication_moment) {
              vehicle.final_communication_moment = '';
            }
          });
          useStore().setLoading(false);
        }
      }).catch(error => {
        this.vehicleList = [];
      });
    },
    setVehicleSelected(vehicle) {
      this.vehicleSelected = vehicle;
      if (this.vehicleSelected) {
        this.vehicleSelected['checkExitShape'] = false;
        this.vehicleSelected['office_name'] = this.listOffices?.find(office => office.office_id === this.vehicleSelected.office_id)?.office_name;
      }
    },

    setIsTrafficJam(isTrafficJam) {
      this.isTrafficJam = isTrafficJam;
    },

    async fetchVehicleDetail() {
      await vehicleDetailService.getDetailVehicle(this.vehicleSelected.vehicle_id).then(res => {
        if (res.data) {
          const storeSP = useStore();
          let translateList = storeSP.translateList;
          this.vehicleSelected['detail'] = res.data;
          this.vehicleSelected.detail.trips.sort((u1, u2) => (u1.trip_start_time.localeCompare(u2.trip_start_time)));
          this.vehicleSelected.detail.trips.forEach(element => {
            let tripHeadSignList = [];
            element.jp_pattern_name.split('→').forEach(tripHeadSign => {
              tripHeadSignList.push(translateList.find(e => (e.field_value === tripHeadSign && e.table_name === CONST.TABLE_NAME.PATTERN))?.translation || tripHeadSign);
            });
            element.jp_pattern_name = tripHeadSignList.join('→');
          });

          this.tripActive = this.vehicleSelected.detail.trips.find(trip => trip.trip_id === this.vehicleSelected.detail.trip_active);
          !this.tripActive && (this.tripActive = this.vehicleSelected.detail.trips.find(trip => trip.trip_id === this.vehicleSelected.detail.trip_time_out));
          const delay = this.vehicleSelected.detail.event_early_delay;
          if (!delay) {
            this.vehicleSelected.detail['delayProcessed'] = null;
          } else if (!delay || delay === '0') {
            this.vehicleSelected.detail['delayProcessed'] = 0;
          } else if (delay.includes('-')) {
            if (delay.includes('min')) {
              this.vehicleSelected.detail['delayProcessed'] = -parseInt(delay.substring(1).slice(0, -3)) * 60;
            } else {
              this.vehicleSelected.detail['delayProcessed'] = -parseInt(delay.substring(1).slice(0, -3));
            }
          } else if (delay.includes('min')) {
              this.vehicleSelected.detail['delayProcessed'] = parseInt(delay.substring(1).slice(0, -3)) * 60;
            } else {
              this.vehicleSelected.detail['delayProcessed'] = null;
            }
        }
        useStore().setLoading(false);
      }).catch(error => {
      });
    },

    fetchNotificationList() {
      vehicleDetailService.getNotificationList().then(res => {
        if (res.data) {
          this.notificationList = res.data.sort((u1, u2) => -(u1?.release_date_time.localeCompare(u2?.release_date_time)));
          useStore().setLoading(false)
        }
      }).catch(error => {
      });
    },

    async fetchListOffices() {
      try {
        const res = await userService.getOffices()
        if (res.data) {
          this.listOffices = JSON.parse(res.data.list_offices);
        }
        return res;
      } catch (error) {
      }
    },

    setNotificationSelected(index) {
      this.notificationSelected = this.notificationList[index];
    },

    async fetchStopList(noBackGround, vehicle?) {
      if (this.vehicleSelected) {
        this.vehicleSelected['stop_list'] = [];
        this.vehicleSelected.trip_active && await vehicleDetailService.getStopList(vehicle ? vehicle.trip_active : this.vehicleSelected.trip_active, noBackGround).then(res => {
        if (res.data) {
          this.vehicleSelected['stop_list'] = res.data
        }
        useStore().setLoading(false);
        }).catch(error => {
        });
      }
    },
    fetchShapeList: async function (listTripId, noBackGround, isFilter, callStopAll?) {
      await vehicleDetailService.getShapeList(listTripId, noBackGround, isFilter, callStopAll).then(res => {
        if (res.data) {
          this.listRoad = res.data;
          if (this.filterStatus) {
            this.filteredBusStops = res.data;
            useStore().setLoading(false);
          }
        }
      }).catch(error => {
      });
    },

    setCheckExitShape: function (checkExitShape) {
      this.vehicleSelected['checkExitShape'] = checkExitShape;
    },

    selectBusStop: function (busStop) {
      this.busStopSelected = busStop;
    },

    setMapType(mapTypeId?: number) {
      if (mapTypeId !== undefined) {
        this.mapTypeId = mapTypeId;
      } else {
        this.mapTypeId++
        if (this.mapTypeId > 3) {
          this.mapTypeId = 0
        }
      }
      this.mapType = MAP_TYPES[this.mapTypeId];
    },

    setNightMap(isNightMap) {
      this.isNightMap = isNightMap;
    },

    setOffice(officeId) {
      this.officeUserId = officeId;
    },
    fetchUserOffice: async function () {
      try {
        const res = await loginService.getOfficeUser('user');
        if (res.status === 200) {
          if (_.isEmpty(res.data)) {
            this.officeUser = '';
            this.officeUserId = '';
          } else {
            this.officeUser = res.data[0].office_name;
            this.officeUserId = res.data[0].office_id;
          }
        }
        return res;
      } catch (error) {
      }
    },
    setMapStatus: function (newMapStatus) {
      this.mapStatus = newMapStatus;
    },
    setListRoad: function (listRoad) {
      this.listRoad = listRoad;
    },

    updateVehicleSelected: function (vehicle) {
      this.vehicleSelected.trip_active = vehicle.trip_active;
      this.vehicleSelected.status_vehicle = vehicle.status_vehicle;
      this.vehicleSelected.time_delay = vehicle.time_delay;
      this.vehicleSelected.location_lat = vehicle.location_lat;
      this.vehicleSelected.location_lon = vehicle.location_lon;

    },
    fetchAllRoute: async function(param, noBackGround?) {
      try {
        const res = await vehicleDetailService.fetchAllRoute(param, noBackGround);
        this.listRoutes = res.data;
        useStore().setLoading(false);
      } catch (error) {
      }
    },
    filterRoute: async function() {

    }
  },
  getters: {
    getListVehicle(state) {
      return state.vehicleList;
    },
    getListVehicleFiltered: (state) => {
      let vehicles = [...state.vehicleList];
      return vehicles.sort((u1, u2) => {
        if (state.sortStatus.prop === 'delayProcessed') {
          if (u1[state.sortStatus.prop] === CONST.TIME_BUS_NOT_ON_SERVICE) {
            return 1
          } else if (u2[state.sortStatus.prop] === CONST.TIME_BUS_NOT_ON_SERVICE) {
            return -1
          } else if (u1[state.sortStatus.prop] === CONST.TIME_BUS_OFFLINE) {
            return 1
          } else if (u2[state.sortStatus.prop] === CONST.TIME_BUS_OFFLINE) {
            return -1
          }
          return (state.sortStatus.direction ? 1 : -1) * (u1[state.sortStatus.prop] - (u2[state.sortStatus.prop]))
        } else if (state.sortStatus.prop === 'final_communication_moment') {
          let compareValue = 1;
          if (!u1[state.sortStatus.prop]?.localeCompare(u2[state.sortStatus.prop])) {
            compareValue = 0;
          }
          if (u1[state.sortStatus.prop].length > u2[state.sortStatus.prop].length) {
            if (!u2[state.sortStatus.prop]) {
              compareValue=  1;
            } else {
              compareValue = -1;
            }
          } else if (u1[state.sortStatus.prop].length < u2[state.sortStatus.prop].length) {
            if (!u1[state.sortStatus.prop]) {
              compareValue=  -1;
            } else {
              compareValue = 1;
            }
          } else {
            compareValue = u1[state.sortStatus.prop]?.localeCompare(u2[state.sortStatus.prop]);
          }
          return (state.sortStatus.direction ? 1 : -1) * compareValue;
        }
        return (state.sortStatus.direction ? 1 : -1) * (u1[state.sortStatus.prop]?.localeCompare(u2[state.sortStatus.prop]))
      });
    },
    getDisplayVehicles: function (state) {
      let filtered;
      if (state.vehicleSelected) {
        filtered = state.vehicleList.filter(vehicle => vehicle.vehicle_id === this.vehicleSelected.vehicle_id);
      } else {
        filtered = state.vehicleList;
      }
      return filtered
        .filter(vehicle => vehicle.location_lat && vehicle.location_lon && ((state.offServiceShow && vehicle.status_vehicle === CONST.STATUS_BUS.OFF_SERVICE)
        || vehicle.status_vehicle !== CONST.STATUS_BUS.OFF_SERVICE))
        .map(vehicle => {
          let iconVehicle = '';
          if (vehicle.status_vehicle === CONST.STATUS_BUS.OFF_SERVICE) {
            iconVehicle = CONST.ICON_BUS.OFF_SERVICE_PATH;
          } else if (vehicle.status_vehicle === CONST.STATUS_BUS.STANDBY || vehicle.status_vehicle === CONST.STATUS_BUS.OFFLINE
            || vehicle.status_vehicle === CONST.STATUS_BUS.LOST_CONNECTION || vehicle.status_vehicle === CONST.STATUS_BUS.POWER_OFF) {
            iconVehicle = CONST.ICON_BUS.STANDY_PATH;
          } else if (vehicle.delayProcessed >= 600) {
            iconVehicle = CONST.ICON_BUS.LATE_10_PATH;
          } else if (vehicle.delayProcessed >= 300) {
            iconVehicle = CONST.ICON_BUS.LATE_5_PATH;
          } else if (vehicle.delayProcessed >= 0) {
            iconVehicle = CONST.ICON_BUS.ON_TIME_PATH;
          } else if (vehicle.delayProcessed < 0) {
            iconVehicle = CONST.ICON_BUS.EARLY_PATH;
          }
          return {
            raw: vehicle,
            vehicleId: vehicle.vehicle_id,
            vehicleNumber: vehicle.vehicle_number,
            vehicleStatus: vehicle.status_vehicle,
            iconVehicle: iconVehicle,
            deviceId: vehicle.device_id,
            officeId: vehicle.office_id,
            accountId: vehicle.account_id,
            lastEditor: vehicle.last_editor,
            lastEdited: vehicle.last_edited_datetime,
            finalCommunicationMoment: vehicle.final_communication_moment,
            lat: vehicle.location_lat,
            lng: vehicle.location_lon
          }
        });
    },
    getCountBusLateOnset: (state) => {
      return state.vehicleList.filter(vehicle => vehicle.delayProcessed >= 300).length
    },

    getCountBusEarlyOnset: (state) => {
      return state.vehicleList.filter(vehicle => (vehicle.delayProcessed < 0 && vehicle.delayProcessed !== CONST.TIME_BUS_NOT_ON_SERVICE && vehicle.delayProcessed !== CONST.TIME_BUS_OFFLINE)).length
    },

    getCountBusDisconnect: (state) => {
      return state.vehicleList.filter(vehicle => (vehicle.status_vehicle === CONST.STATUS_BUS.LOST_CONNECTION || vehicle.status_vehicle === CONST.STATUS_BUS.OFFLINE)).length
    },
    getStopList: (state) => {
      if (!state.vehicleSelected?.stop_list) return [];
      const storeSP = useStore();
      return state.vehicleSelected?.stop_list?.map((busStop) => {
        return {
          raw: busStop,
          stopId: busStop.stop_id,
          lat: Number(busStop.stop_lat),
          lng: Number(busStop.stop_lon),
          stopName: storeSP.translateList.find(e => (e.field_value === busStop.stop_name && e.table_name === CONST.TABLE_NAME.STOPS))?.translation || busStop.stop_name,
          stopSequence: busStop.stop_sequence
        };
      }).sort((b1, b2) => b1.stopSequence - b2.stopSequence);
    },

    getListRoad: (state) => {
      const listRoadTemp = []
      if (state.listRoad.length === 0) return [];
      state.listRoad.forEach(element => {
        listRoadTemp.push(element.list_shape.map((shape) => {
          return {
            lat: Number(shape.shape_pt_lat),
            lng: Number(shape.shape_pt_lon),
            color: shape.route_color
          };
        }))
      });
      return listRoadTemp;
    },
    getSearchList: function(state) {
      return state.busStopShow ? this.getListVehicleFiltered.concat(this.getStopList) : this.getListVehicleFiltered;
    },
    getListRoute: function(state) {
      return state.listRoutes;
    },
    getFilteredBusStops: function (state) {
      const listRoute = _.cloneDeep(state.listRoutes);
      const sorted = _.sortBy(state.filteredBusStops, obj => {
        const index = listRoute.findIndex(item => item.jp_pattern_id === obj.pattern_id);
        return index;
      });
      return sorted;
    }
  }
});
