import { defineStore } from 'pinia';
import { HttpStatusCode } from 'axios';
import { CONST } from '@/constants';
import { useDateFormat, DATE_FORMAT, useDateUtils } from '@/shared/composables';
import StopService from '@/services/monitoring/detail/stop.service';
import { useStore } from '@/store';
import { computed } from 'vue';
import _ from 'lodash';

const stopService = new StopService();
export interface DetailStopStateStorable {
  busStop: Object;
  selectedDate: Date;
  routes: Object;
  schedules: Array<any>;
}

const translateTripHeadSignInArr = (trips,table=null) => {
  const storeSP = useStore();
  const translateList = computed(() => storeSP.translateList);
  const systems = [];
  const dataTran = translateList.value.filter(record => record.table_name === table);
  if (trips[0] !== '') {
    if (!_.isEmpty(dataTran)) {
      trips.forEach(trip => {
        const foundData = dataTran.find(record => record.field_value === trip);
        if (foundData) {
          systems.push(foundData?.translation);
        } else {
          return null;
        }
      });
    } else {
      return null
    }
    return _.isEmpty(systems) ? null : systems;
  } else if (trips[0] !== '' && !_.isEmpty(dataTran)) {
    trips.forEach(trip => {
      systems.push(trip);
    })
    return systems
  } else {
    return null;
  }
}

export const defaultDetailStopState: DetailStopStateStorable = {
  busStop: {},
  selectedDate: useDateUtils().handleConvertTime(new Date()),
  routes: {},
  schedules: []
}

export const useDetailStopStore = defineStore('detailStopStore', {
  state: (): DetailStopStateStorable => ({ ...defaultDetailStopState }),
  getters: {
    getSchedules: function (state) {
      return state.schedules.map(function (schedule) {
        return {
          departureTime: schedule.event_time,
          vehicleNumber: schedule.vehicle_id,
          delay: schedule.event_early_delay,
          routeShortName: schedule.route_name,
          routeName: schedule.trip_name,
          work: schedule.trip_id,
          trip_headsign: schedule.trip_headsign,
          stop_headsign: schedule.stop_headsign,
        }
      });
    },
    getRoutesViaStop: function (state) {
      let routes = [];
      const currentLanguage = localStorage.currentLanguage;
      Object.keys(state.routes).forEach(function (routeId) {
        let route = state.routes[routeId];
        let pattern = currentLanguage === 'ja' ? route.jp_pattern_name :  translateTripHeadSignInArr(route.trip_headsign, CONST.TABLE_NAME.TRIPS) || route.jp_pattern_name;
        pattern.forEach(function (pattern) {
          let routeViaStop = {
            routeId: routeId,
            routeColor: route.route_color,
            routeName: route.route_name,
            specificRouteName: pattern
          }
          routes.push(routeViaStop);
        });
      });
      return routes;
    },
    getSelectedDate: function (state) {
      let formatted = useDateFormat().formatDate(state.selectedDate, DATE_FORMAT);
      let dayName = CONST.DAY_NAMES[state.selectedDate.getDay()];
      return {
        value: state.selectedDate,
        formatted: formatted,
        dayName: dayName
      };
    },
    allowNextDate: function () {
      let currentDate = new Date();
      let currentDateString = useDateFormat().formatDate(currentDate, DATE_FORMAT);
      return this.getSelectedDate.formatted < currentDateString;
    },

    allowPrevDate: function () {
      let currentDate: any = new Date();
      let fourteenDaysAgo = new Date(currentDate - 14 * 24 * 60 * 60 * 1000);
      let fourteenDaysAgoString = useDateFormat().formatDate(fourteenDaysAgo, DATE_FORMAT);
      return this.getSelectedDate.formatted > fourteenDaysAgoString;
    }
  },
  actions: {
    resetSelectedDate: function() {
      this.selectedDate =  useDateUtils().handleConvertTime(new Date());
    },

    fetchStopSchedule: async function (busStopId: string, date: string) {
      try {
        let res = await stopService.fetchStopSchedule(busStopId, date);
        if (res.status === HttpStatusCode.Ok) {
          this.schedules = res.data;
          useStore().setLoading(false);
        }
      } catch (error) {
        this.schedules = [];
      }
    },
    fetchRouteViaStop: async function (busStopId: string, date: string) {
      try {
        let res = await stopService.fetchRouteViaStop(busStopId, date);
        if (res.status === HttpStatusCode.Ok) {
          this.routes = res.data;
          useStore().setLoading(false);
        }
      } catch (error) {
        this.routes = {};
      }
    },
    setSelectedDate: function (newDate: Date) {
      this.selectedDate = newDate;
    }
  }
});
