import { defineStore } from 'pinia';
import BusService from '@/services/bus/bus.service';
import UserService from '@/services/user/user.service';
import { HttpStatusCode } from 'axios';
import { CONST } from '@/constants';
import { useStore } from '@/store';

const ASC = true;
const DESC = false;

const busService = new BusService();
const userService = new UserService();

export interface BusStateStorable {
  listBus: Array<any>;
  listDeviceUnuse: Array<any>;
  selectedBus: any;
  keySearch: string;
  enableSearch: boolean;
  filtered: {
    office: string,
    display: string
  };
  sortStatus: {
    prop: string,
    direction: boolean
  };
  listOffices: Array<any>;
  count: number;
  filterCondition: string;
}

export const defaultBusState: BusStateStorable = {
  listBus: [],
  listDeviceUnuse: [],
  keySearch: '',
  enableSearch: false,
  filtered: {
    office: '',
    display: ''
  },
  sortStatus: {
    prop: 'vehicleId',
    direction: ASC
  },
  selectedBus: null,
  listOffices: [],
  count: 0,
  filterCondition: ''
}

export const useBusStore = defineStore('busStore', {
  state: (): BusStateStorable => ({ ...defaultBusState }),
  getters: {
    getAllBus: (state) => {
      return state.listBus;
    },
    getBusMatched: function(state) {
      if (state.enableSearch && state.keySearch) {
        return this.getBusByOffice.filter(function (bus) {
          let isMatch = false;
          Object.keys(bus).forEach(function (key) {
            if ((String(bus[key]).toLowerCase().includes(state.keySearch.toLowerCase().toString())) && key !== "accountId") {
              isMatch = true;
            }
          });
          return isMatch;
        });
      }

      return [];
    },
    getBusByOffice: (state) => {
      let prop = state.sortStatus.prop;
      let filtered = state.filtered.office ? state.listBus.filter(bus => bus.officeName === state.filtered.office) : state.listBus;
      filtered = [...filtered].sort((b1, b2) => (state.sortStatus.direction ? 1 : -1) * b1[prop]?.localeCompare(b2[prop]));
      return filtered;
    },
    getMapOffice: function (state) {
      const mapOffice = new Map();
      for (const element of state.listOffices) {
        mapOffice.set(element.office_id, element);
      }
      return mapOffice;
    },
    getKeySearch: function(state) {
      return state.keySearch;
    },
    getCount: function(state) {
      return state.count;
    }
  },
  actions: {
    resetSortStatus() {
      this.sortStatus = { ...defaultBusState.sortStatus };
    },
    search (keySearch) {
      this.keySearch = keySearch;
      this.enableSearch = true;
    },
    filterByOffice: function (office) {
      this.filtered.office = office;
      this.filtered.display = office;
    },
    addBus: async function (bus) {
      await busService.addBus(bus);
      useStore().setLoading(false);
    },
    editBus: async function (oldValue, newValue, isConfirmContinueEdit) {
      await busService.editBus(oldValue, newValue, isConfirmContinueEdit);
      useStore().setLoading(false);
    },
    deleteBus: async function (bus) {
      await busService.deleteVehicle(bus.vehicleId);
      useStore().setLoading(false);
    },
    selectBus: function (bus) {
      this.selectedBus = bus === this.selectedBus ? null : bus;
    },
    sort: function (prop) {
      this.sortStatus.direction = this.sortStatus.prop === prop ? !this.sortStatus.direction : ASC;
      this.sortStatus.prop = prop;
    },
    fetchAllBus: async function () {
      try {
        const res = await busService.fetchAllBus();
        let offices = this.getMapOffice;
        if (res && res.status === HttpStatusCode.Ok) {
          this.listBus = res.data.map(function (bus) {
            const busObj = {
              officeId: bus.office_id,
              officeName: offices.get(bus.office_id)?.office_name,
              vehicleId: bus.vehicle_id,
              vehicleNumber: bus.vehicle_number || '',
              deviceId: bus.device_id || '',
              lastSend: bus.last_send || '',
              lastEditor: bus.last_editor ? (bus.last_editor === CONST.ROOT_NAME.ROOT ? CONST.ROOT_NAME.LECIP : bus.last_editor): '',
              lastEditedDatetime: bus.last_edited_datetime || '',
              accountId: bus.account_id
            };
            return busObj;
          });
          useStore().setLoading(false);
        } else {
          this.listBus = [];
        }
      } catch (error) {
        this.listBus = [];
      }
    },
    fetchDeviceUnuse: async function() {
      try {
        const res = await busService.fetchDeviceUnuse();
        if (res && res.status === HttpStatusCode.Ok) {
          let i = 0;
          this.listDeviceUnuse = res.data.map(function (device) {
            i++;
            return {
              registeName: device.register_name,
              officeId: device.office_id,
              ipAddress: device.ip_address,
              vehicleId: device.vehicle_id,
              lastSend: device.last_send,
              deviceId: device.device_id,
              accountId: device.account_id,
              registerDatetime: device.register_datetime
            }
          });
          useStore().setLoading(false);
        } else {
          this.listDeviceUnuse = [];
        }
      } catch (error) {
        this.listDeviceUnuse = [];
      }
    },
    fetchOffices: async function() {
      try {
        const res = await userService.getOffices();
        if (res && res.status === HttpStatusCode.Ok) {
          this.listOffices = JSON.parse(res.data.list_offices);
        } else {
          this.listOffices = [];
        }
        useStore().setLoading(false);
      } catch (error) {
        this.listOffices = [];
      }
    },
    setKeySearch: function(keySearch) {
      this.keySearch = keySearch
    },
    setCount: function(param) {
      this.count = param;
    }
  }
});
