import { defineComponent,ref, watch  } from 'vue';

export default defineComponent({
  compatConfig: { MODE: 3 },
  emits: ['hide'],
  props: {
    message: String,
    visible: Boolean,
    type: String,
    icon: String
  },
  setup(props, {emit}) {
    const timeOut = 3000;
    const show = ref(false)
    watch(() => props.visible, (newVisible) => {
      show.value = newVisible;
      if (newVisible) {
        setTimeout(() => {
          show.value = false;
          emit('hide');
        }, 3000);
      }
    });
    return {
      timeOut,
      show
    }
  },
});
