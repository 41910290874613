import { defineComponent, ref, watch, inject, computed, onMounted, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { CONST, KEYS } from '@/constants';
import LoginService from '@/services/account/login.service';
import { HttpStatusCode } from 'axios';
import { useStore, useRouteStore, useRoleUserStore } from '@/store';
import { useJwt } from '@vueuse/integrations/useJwt'
import Setting from './setting/setting.vue';
import Help from '@/features/core/navbar/help/help.vue'
import _ from "lodash";

export default defineComponent({
  compatConfig: { MODE: 3 },
  components: { Setting, Help },
  setup() {
    const CLASS_NAME = CONST.CLASS_NAME_SCREEN
    const ROLE_LIST = CONST.ROLE_NAME_MAPPING
    const isOpenMenu = ref(false);
    const isOpenHelp = ref(false);
    const navClassName = ref();
    const router = useRouter();
    const userId = ref('');
    const roleId = ref();
    const store = useStore();
    const routeStore = useRouteStore();
    const roleUserStore = useRoleUserStore();
    var screenList = [];
    const loginService = inject('loginService', () => new LoginService(), true);
    const navTitle = ref();
    const menuList = ref([]);
    const isEditingRoute = computed(() => routeStore.isEditing);
    const isOpenSetting = computed(() => store.isOpenSetting);
    const permissionList = computed(() => store.permissionList);
    const hasChangedRoleUser = computed(() => roleUserStore.getHasChanged);

    watch(() => [store.userId, permissionList.value], () => {
      userId.value = sessionStorage.getItem(CONST.ACCOUNT.USER_ID);
      if (sessionStorage.getItem(KEYS.KEY_API_ID_TOKEN)) {
        decodeToken();
        createMenuList();
      } else {
        navTitle.value = '';
      }
    });
    // [headerComponent]　表示しない場合
    // [route]で[meta.showHeader: false]設定してください。
    const flag = ref();
    const isLoginPage = ref(false);
    router.beforeResolve(async (to, from, next) => {
      isLoginPage.value =  ['/login', '/forgot-password', '/reset-password'].includes(to.path);
      flag.value = !to.meta.hideHeader
      next()
    })

    onMounted(() => {
      if (sessionStorage.getItem(KEYS.KEY_API_ID_TOKEN)) {
        store.setUserId(sessionStorage.getItem(CONST.ACCOUNT.USER_ID));
        decodeToken();
        createMenuList();
      }
    });

    const createMenuList = () => {
      if (roleId.value !== CONST.ROLE_NAME_MAPPING.lecip) {
        screenList = [...CONST.SCREEN_LIST];
      } else {
        screenList = [...CONST.SCREEN_LIST_ADMIN];
      }
      menuList.value = [];
      screenList.forEach(screen => {
        var screenSelect = { ...screen, status: false };
        if (permissionList.value?.find(e => e.id == screen.id)) {
          screenSelect.status = true;
        }
        if (screenSelect.id !== "id_99" && screenSelect.id !== "id_98") {
          menuList.value.push(screenSelect);
        }
      });
    }

    // Check router
    watch(
      () => router.currentRoute.value,
      value => {
        var screenSelect = screenList.find(e => e.name == value.name);
        // check exit screenSelect
        if (screenSelect) {
          navClassName.value = CLASS_NAME[screenSelect.id];
          navTitle.value = screenSelect.id;
        }
      },
      {
        deep: true,
        immediate: true
      },
    );

    // Check logout when edit route
    watch(() => routeStore.isCallLogout, value => {
      if (value) {
        routeStore.setIsCallLogout(false);
        logout();
      }
    });

    //choose option menu
    const chooseOption = (screenId, className, event?) => {
      var screenSelect = screenList.find(e => e.id == screenId);
      if (!isEditingRoute.value && !hasChangedRoleUser.value) {
        navClassName.value = className;
        navTitle.value = screenId;
        router.push({ name: screenSelect.name });
        window.location.href = screenSelect.path;
      } else {
        if (isEditingRoute.value) {
          routeStore.setIsShowWanningModal(true);
          routeStore.setScreenSelectName(screenSelect.name);
        } else if (hasChangedRoleUser.value) {
          roleUserStore.setShowWanningModal(true);
          roleUserStore.setActionAcceptWarn(() => {
            event?.target?.click();
          });
        }
      }
    }

    //change show Menu
    const changeShowMenu = () => {
      isOpenMenu.value = !isOpenMenu.value;
    }

    const decodeToken = () => {
      const payload: any = useJwt(sessionStorage.getItem(KEYS.KEY_API_ID_TOKEN)).payload;
      if (payload?.value) {
        roleId.value = payload.value.role_id;
      }
    }

    const clearState = () => {
      store.setTime("");
      sessionStorage.clear();
      navClassName.value = "";
      userId.value = "";
      navTitle.value = "";
      store.setUserId('');
    }

    const logout = (event?) => {
      if (!isEditingRoute.value && !hasChangedRoleUser.value) {
        var model = {
          userId: sessionStorage.getItem(CONST.ACCOUNT.USER_ID),
          accountId: sessionStorage.getItem(CONST.ACCOUNT.ACCOUNT_ID)
        }
        loginService.logout(model).then(res => {
          if (res.status !== HttpStatusCode.Ok) {
            return;
          }
          clearState();
          router.push({ name: 'Login' });
          useStore().setLoading(false);
        }).catch(error => {
          // TODO:
        });
      } else {
        if (isEditingRoute.value) {
          routeStore.setIsShowWanningModal(true);
        } else if (hasChangedRoleUser.value) {
          roleUserStore.setShowWanningModal(true);
          roleUserStore.setActionAcceptWarn(() => {
            event?.target?.click();
          });
        }
      }
    }

    const handleOpenSetting = () => {
      store.openSetting(true);
    }

    return {
      t$: useI18n().t,
      navTitle,
      chooseOption,
      menuList,
      isOpenMenu,
      changeShowMenu,
      navClassName,
      isOpenSetting,
      CLASS_NAME,
      userId,
      roleId,
      ROLE_LIST,
      logout,
      flag,
      isLoginPage,
      isOpenHelp,
      handleOpenSetting,
      clearState
    };
  },
});
