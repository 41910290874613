import { defineStore } from 'pinia';
import _ from "lodash"
import HistoryService from '@/services/history/history.service';
import ScheduleService from '@/services/schedule/schedule.service';
import { useDateFormat, useDateUtils } from '@/shared/composables';
import AccountService from '@/services/account/account.service';
import { useStore } from '@/store';
import { CONST } from '@/constants';
import dayjs from 'dayjs';

const ASC = false;
const historyService = new HistoryService();
const scheduleService = new ScheduleService();
const accountService = new AccountService();

export interface HistoryStateStorable {
  listHistory: Array<any>;
  listHistoryExport: Array<any>;
  sortStatus: {
    prop: string;
    direction: boolean;
  };
  keySearch: string;
  enableSearch: boolean;
  listEvent: Array<any>;
  listOffice: Array<any>;
  listVehicle: Array<any>;
  listRoutes: Array<any>;
  filters: Array<any>;
  selectedDate: any;
  selectedMonth: any;
  i18n: any;
  count: number,
  officeOfUser: string;
  gpsFilter: Object;
  dataGps: Object;
}

export const defaultHistoryState: HistoryStateStorable = {
  listHistory: [],
  listHistoryExport: [],
  sortStatus: {
    prop: 'event_occurence_time',
    direction: ASC,
  },
  keySearch: "",
  enableSearch: false,
  listEvent: [],
  listOffice: [],
  listVehicle: [],
  listRoutes: [],
  filters: [],
  selectedDate: useDateUtils().handleConvertTime(new Date(new Date().setDate(new Date().getDate() - 1))),
  selectedMonth: useDateUtils().handleConvertTime(new Date(new Date().setMonth(new Date().getMonth() - 1))),
  i18n: null,
  count: 0,
  officeOfUser: "",
  gpsFilter: {
    vehicles: [],
    stops: []
  },
  dataGps: {
    stop: [],
    marker: []
  }
};

export const useHistoryStore = defineStore('historyStore', {
  state: (): HistoryStateStorable => ({ ...defaultHistoryState }),
  getters: {
    getListHistoryFiltered: (state) => {
      let historys = state.listHistory;
      if(historys.length > 5000) {
        return null
      }
      const storeSP = useStore();
      let translateList = storeSP.translateList;
      if (translateList.length > 0) {
        state.listHistory.forEach(element => {
          element.stop_name = translateList.find(e => (e.field_value === element.stop_name && e.table_name === CONST.TABLE_NAME.STOPS))?.translation || element.stop_name;
          element.route = translateList.find(e => (e.field_value === element.route && e.table_name === CONST.TABLE_NAME.ROUTES))?.translation || element.route;
          if (localStorage.currentLanguage !== 'ja') {
            const tripHeadSign = translateList.find(e => e.field_value === element.trip_headsign && e.table_name === "trips")?.translation;
            element.system = tripHeadSign || element.system
          }
        });
      }
      return [...historys].sort((u1, u2) => {
        if(_.isArray(u1[state.sortStatus.prop])){
          return (state.sortStatus.direction ? 1 : -1) * u1[state.sortStatus.prop].join(",").localeCompare(u2[state.sortStatus.prop].join(","))
        }
        if(_.isNumber(u1[state.sortStatus.prop])){
          return (state.sortStatus.direction ? u2[state.sortStatus.prop] - u1[state.sortStatus.prop] : u1[state.sortStatus.prop] - u2[state.sortStatus.prop])
        }
        if(state.sortStatus.prop === "number_of_passes"){
          return (state.sortStatus.direction ? parseInt(u2[state.sortStatus.prop].split("/")[0]) - parseInt(u1[state.sortStatus.prop].split("/")[0]) : parseInt(u1[state.sortStatus.prop].split("/")[0]) - parseInt(u2[state.sortStatus.prop].split("/")[0]))
        }
        return (state.sortStatus.direction ? 1 : -1) * u1[state.sortStatus.prop]?.localeCompare(u2[state.sortStatus.prop])
      }
      );
    },
    getListHistoryExport: state => {
      let historys = state.listHistoryExport;
      return [...historys].sort((u1, u2) => {
        if(_.isArray(u1[state.sortStatus.prop])){
          return (state.sortStatus.direction ? 1 : -1) * u1[state.sortStatus.prop].join(",").localeCompare(u2[state.sortStatus.prop].join(","))
        }
        return (state.sortStatus.direction ? 1 : -1) * u1[state.sortStatus.prop]?.localeCompare(u2[state.sortStatus.prop])
      }
      );
    },
    getHisotryMatched: function(state) {
      if (state.enableSearch && state.keySearch) {
        const keyInvalid = ['location', 'bus_stop_id'];
          return this.getListHistoryFiltered.filter(function (record) {
            if (state.keySearch.includes('分')) {
              state.keySearch = state.keySearch.replace('分', 'min');
            } else if (state.keySearch.includes('秒')) {
              state.keySearch = state.keySearch.replace('秒', 'sec');
            }
            let isMatch = false;
            Object.keys(record).forEach(function (key) {
              if (String(record[key]).toLowerCase().includes(state.keySearch.toLowerCase().toString()) && !keyInvalid.includes(key)) {
                isMatch = true;
              }
            });
            return isMatch;
          });
      }
      return [];
    },
    getListEvent: function(state) {
      return state.listEvent;
    },
    getListOffice: function(state) {
      return state.listOffice;
    },
    getMapOffice: function (state) {
      const mapOffice = new Map();
      for (const element of state.listOffice) {
        mapOffice.set(element.office_id, element);
      }
      return mapOffice;
    },
    getRoutes: function (state) {
      return state.listRoutes;
    },
    getFilters: function (state) {
      let filtered = {
        info_other: state.filters["info_other"],
        info_stops: [],
        info_trips: [],
        info_routes: []
      };
      if (localStorage.currentLanguage === 'ja') {
        filtered.info_trips = _.unionBy(state.filters["info_trips"],'jp_pattern_name');
      } else {
        filtered.info_trips = _.uniqWith(state.filters["info_trips"], (t1,t2) => t1.jp_pattern_name === t2.jp_pattern_name && t1.trip_headsign === t2.trip_headsign);
      }
      filtered.info_stops = state.filters["info_stops"];
      filtered.info_routes = _.unionBy(state.filters["info_trips"],'route_name');
      return filtered ;
    },
    getVehicles: function (state) {
      return state.listVehicle;
    },
    getOfficeOfUser: function(state) {
      return state.officeOfUser;
    }
  },
  actions: {
    resetSortStatus() {
      this.sortStatus = { ...defaultHistoryState.sortStatus };
    },
    search (keySearch) {
      this.keySearch = keySearch;
      this.enableSearch = true;
    },
    sort: function (prop) {
      this.sortStatus.direction = this.sortStatus.prop === prop ? !this.sortStatus.direction : ASC;
      this.sortStatus.prop = prop;
    },
    fetchListHistory: async function(params){
      const param = {
        eventTimeFrom: this.renderTimeDatePicker(params.startDate),
        eventTimeTo:this.renderTimeDatePicker(params.endDate),
        officeId: params.office || "all",
        routeName: params.route || "all",
        tripName: params.pattern.jp_pattern_name || "all",
        busStopName: params.stop || "all",
        vehicleId: params.vehicle || "all",
        eventEarlyDelay: params.eventDelay || "all",
        event: params.checkAll ? "all" : params.event.map(item => item.name)?.join(',')
      }

      let offices = this.getMapOffice;
      const res = await historyService.getListHistory(param);
      if(res.status === 200) {
        this.listHistory = res.data.map(item => {
          return {
            history_id: item.history_id,
            bus_stop_id: item.bus_stop_id,
            event_occurence_time: item.event_time,
            vehicle_no: item.vehicle_id,
            event: this.i18n(`historyConfirm.event.${item.event_type}`),
            early_departure_delay: item.event_early_delay,
            event_early_delay_sec: item.event_early_delay_sec,
            number_of_passes: item.number_of_passes,
            stop_name: CONST.EVENT_HIDDEN.includes(this.i18n(`historyConfirm.event.${item.event_type}`)) ? '' : item.bus_stop_name,
            route: item.route_name,
            system: item.trip_name,
            flight: item.trip_id,
            business_office: offices?.get(item.office_id)?.office_name,
            location: {
              lat: Number(item.location.lat),
              lng: Number(item.location.lon)
            },
            trip_headsign: item?.trip_headsign || '',
          }
        })
        useStore().setLoading(false);
      } else {
        this.listHistory= [];
      }
      return res;
    },

    fetchOffice: async function () {
      try {
        const res = await historyService.getOffices();
        if(res.status === 200) {
          this.listOffice = JSON.parse(res.data.list_offices);
        }
        return res;
      } catch (err) {
      }
    },
    fetchFilterHistory: async function (param) {
      const res = await historyService.getFilterHistory(param);
      if(res.status === 200) {
        this.listVehicle = res.data?.info_other?.vehicle_id.sort((i1,i2) =>  i1["vehicle_id"].localeCompare(i2["vehicle_id"]));
        this.listRoutes = _.unionBy(res.data?.info_trips, 'route_id');
        this.filters = res.data;
        useStore().setLoading(false);
      }
    },
    getAcency: async function () {
      return await accountService.getAgency();
    },
    getFileExportMonth: async function(params) {
      const data = {
        date: useDateFormat().formatDate(params.exportDate,'YYYY-MM'),
        offfice: params.filterCondition
      }
      return await historyService.getFileExportMonth(data);
    },
    getFileExportDay: async function(params) {
      const data = {
        date: useDateFormat().formatDate(params.exportDate,'YYYY-MM-DD'),
        offfice: params.filterCondition
      }
      return await historyService.getFileExportDay(data);
    },
    fetchListEvent: async function() {
      try {
        const res = await historyService.getFilterHistoryHidden();
        this.listEvent = res.data?.info_other?.event_type.map((event,index) => {
          return {
            id: index,
            name: event
          }
        })
        return res;
      } catch (error) {
      }
    },
    setI18n: function(param) {
      this.i18n = param;
    },
    fetchOfficeOfUser: async function(){
      const res = await historyService.getOfficeOfUser("user");
      if(res.status === 200){
        if(_.isEmpty(res.data)) {
          this.officeOfUser = '';
        }else {
          this.officeOfUser = res.data[0].office_id;
        }
        return res;
      }
    },
    fetchDataToCheck: async function(params) {
      const param = {
        eventTimeFrom: this.renderTimeDatePicker(params.startDate),
        eventTimeTo:this.renderTimeDatePicker(params.endDate),
        officeId: params.office || "all",
        routeName: params.route || "all",
        tripName: params.pattern.jp_pattern_name || "all",
        busStopName: params.stop || "all",
        vehicleId: params.vehicle || "all",
        eventEarlyDelay: params.eventDelay || "all",
        event: params.checkAll ? "all" : params.event.map(item => item.name)?.join(',')
      }

      const res = await historyService.getListHistory(param);
      return res;
    },
    renderTimeDatePicker: function (param) {
      const date = dayjs(param).format("YYYY-MM-DD");
      const hour = dayjs(param).format("HH");
      const minSec = dayjs(param).format("mm:ss");
      const weekday = dayjs(param).format("(dd)");

      let hourText;
      if (hour === "00") {
        hourText = "24";
      } else if (hour === "01") {
        hourText = "25";
      } else if (hour === "02") {
        hourText = "26";
      } else if (hour === "03") {
        hourText = "27";
      } else {
        hourText = dayjs(param).format("HH");
      }
      return `${date} ${hourText}:${minSec}`
    },
    fetchVehicleGpsFilter: async function (params) {
      try {
        const res = await historyService.getVehicleGpsFilter(params);
        this.gpsFilter.vehicles = res.data.info_vehicles;
        useStore().setLoading(false);
      } catch (error) {
      }
    },
    fetchStopGpsFilter: async function (params) {
      try {
        const res = await historyService.getStopGpsFilter(params);
        this.gpsFilter.stops = res.data.info_stops.sort((i1,i2) =>  i1.localeCompare(i2,'ja'));
        useStore().setLoading(false);
      } catch (error) {
      }
    },
    fetchDataGps: async function (params) {
      try {
        const convertedParams = {
          startDate: this.renderTimeDatePicker(params.startDate),
          endDate: this.renderTimeDatePicker(params.endDate),
          vehicle: params.vehicleId,
          stop: params.stop,
        }
        const res = await historyService.getDataGps(convertedParams);
        this.dataGps.stop = res.data.gps_stop;
        this.dataGps.marker = res.data.gps_data.filter(record =>  record.time > 0 && record.lat >= -90 && record.lat <= 90 && record.lon >= -180 && record.lon <= 180);
        useStore().setLoading(false);
      } catch (error) {
      }
    }
  },
});
