import { defineStore } from 'pinia';
import UserNotificationService from '@/services/user-notificaiton/user-notification.service';
import { HttpStatusCode } from 'axios';
import { useStore } from '@/store';
const ASC = true;
const DESC = false;
const DEFAULT_ERR_CODE = 'msg0000';

export interface UserNotificationStateStorable {
  listNotification: Array<any>;
  selectedNotification: any;
  sortStatus: {
    prop: string,
    direction: Boolean
  };
  listAccount: Array<any>,
  msgErr: string,
  i18n: any;
}

const userNotificationService = new UserNotificationService();

export const defaultNotificationState: UserNotificationStateStorable = {
  listNotification: [],
  sortStatus: {
    prop: 'releaseDateTime',
    direction: DESC
  },
  selectedNotification: null,
  listAccount: [],
  msgErr: '',
  i18n: null
}

export const useUserNotificationStore = defineStore('userNotificationStore', {
  state: (): UserNotificationStateStorable => ({ ...defaultNotificationState }),
  getters: {
    getAllNotification: (state) => {
      let filtered = state.listNotification.map((notification) => {
        return {
          accountId: notification.account_id,
          content: notification.content_notification,
          startDate: notification.start_date,
          endDate: notification.end_date,
          title: notification.title,
          releaseDateTime: notification.release_date_time,
          publisher: notification.publisher === 'root' ? 'lecip' : notification.publisher,
          notificationId: notification.notification_id,
          link: notification.link
        }
      }).sort((u1, u2) => (state.sortStatus.direction ? 1 : -1) * u1[state.sortStatus.prop].localeCompare(u2[state.sortStatus.prop]));
      return filtered;
    }
  },
  actions: {
    resetSortStatus() {
      this.sortStatus = { ...defaultNotificationState.sortStatus };
    },
    ini: async function () {
      this.selectedNotification = null;
      this.sortStatus = {
        prop: 'releaseDateTime',
        direction: DESC
      };
    },
    selectNotification: function (notification) {
      this.selectedNotification = this.selectedNotification?.notificationId !== notification?.notificationId ? notification : null;
    },
    sort: function (prop) {
      this.sortStatus.direction = this.sortStatus.prop === prop ? !this.sortStatus.direction : ASC;
      this.sortStatus.prop = prop;
    },
    fetchAllNotification: async function () {
      try {
        const res = await userNotificationService.fetchAllNotification();
        if (res.status === HttpStatusCode.Ok) {
          this.listNotification = res.data;
        } else {
          this.listNotification = [];
        }
        useStore().setLoading(false);
      } catch (error) {
        this.listNotification = [];
      }
    },
    addNotification: async function (arg: {startDate: string, endDate: string, title: string,  content: string, link: string}) {
      const params = {
        start_date: arg.startDate,
        end_date: arg.endDate,
        title: arg.title,
        content_notification: arg.content,
        link: arg.link
      };
      try {
        let res = await userNotificationService.addNotification(params);
        if (res.status === 200) {
          useStore().setLoading(false);
          return res;
        }
      } catch (error) {
        this.msgErr = this.i18n(`error.${error.data.message.toLowerCase()}`)
      }
    },
    updateNotification: async function (arg: {startDate: string, endDate: string, title: string,  content: string, link: string}) {
      const params = {
        start_date: arg.startDate,
        end_date: arg.endDate,
        title: arg.title,
        content_notification: arg.content,
        link: arg.link,
        notification_id: this.selectedNotification.notificationId
      };
      try {
        let res = await userNotificationService.updateNotification(params);
        if (res.status === 200) {
          useStore().setLoading(false);
          return res;
        }
      } catch (error) {
        this.msgErr = this.i18n(`error.${error.data.message.toLowerCase()}`)
      }
    },
    deleteNotification: async function (notificationId: string) {
      try {
        let res = await userNotificationService.deleteNotification(notificationId);
        if (res.status === 200) {
          useStore().setLoading(false);
          return res;
        }
      } catch (error) {
          this.msgErr = this.i18n(`error.${error.data.message.toLowerCase()}`)
      }
    },
    setI18n: function(param) {
      this.i18n = param;
    },
  }
});
