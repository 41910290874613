import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(), _createBlock(_component_Datepicker, {
    modelValue: _ctx.newDate,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newDate) = $event)),
    language: _ctx.t$('detailStop.lang'),
    iconHeight: 20,
    iconWidth: 20,
    openDate: _ctx.openDate
  }, null, 8 /* PROPS */, ["modelValue", "language", "openDate"]))
}