import { customAxios } from "@/shared/config/axios-interceptor";
import _ from 'lodash';

export default class ScheduleService {
  public async getOffices(date): Promise<any> {
    try {
      const res = await customAxios.get(`/users/offices?pdate=${date}`);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };
  public async getJobs(params): Promise<any> {
    try {
      const res = await customAxios.get(`/jobs?job_date=${params.date}&office_id=${params.officeId}`);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };
  public async setJobs(params): Promise<any> {
    try {
      let tripClone = _.cloneDeep(params.trips);
      tripClone = tripClone.sort((s1,s2) => 1 * s1['startTime'].localeCompare(s2['startTime']));
      const data = {
        account_id: params.job.accountId,
        job_id: params.job.jobId,
        driver_id: params.job.driverId,
        job_date: params.job.jobDate,
        office_id: params.job.office,
        vehicle_id: params.job.vehicleNumber,
        trips: tripClone
      }
      tripClone.forEach(trip => {
        trip.trip_end_time = trip.endTime;
        trip.trip_start_time = trip.startTime;
        trip.trip_id = trip.tripId;
        trip.jp_pattern_name = trip.pattern;
        trip.service_id = trip.serviceId;
        trip.route_id = trip.routeId;
        trip.route_name = trip.routeName;
        trip.jp_pattern_id = trip.jpPatternId;
        trip.trip_headsign = trip.tripHeadSign;
        trip.stop_headsign = trip.stopHeadSign;
        delete trip.endTime;
        delete trip.startTime;
        delete trip.tripId;
        delete trip.pattern;
        delete trip.serviceId;
        delete trip.routeId;
        delete trip.routeName;
        delete trip.jpPatternId;
        delete trip.tripHeadSign;
        delete trip.stopHeadSign;
      })
      const res = await customAxios.post('/jobs',JSON.stringify(data));
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };
  public async getJob(params): Promise<any> {
    try {
      const res = await customAxios.get(`/jobs/filter?job_id=${params.work.jobId}&office_id=${params.work.office}&get_filter_data=true&service_id=${params.filter.serviceId}&route_id=${params.filter.routeId}&pattern_id=${params.filter.patternId}`);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };
  public async getFilters(params): Promise<any> {
    try {
      const res = await customAxios.get(`/jobs/filter?job_date=${params.jobDate}&office_id=${params.officeId}`,);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };
  public async getDataExport(params): Promise<any> {
    try {
      const res = await customAxios.get(`/jobs/export?job_date_from=${params.startDate}&job_date_to=${params.endDate}&office_id=${params.officeId}`);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async replaceJobs(jobOld, jobNew): Promise<any> {
    try {
      const data = {
        job_old: {
          account_id: jobOld.accountId,
          job_date: jobOld.jobDate,
          vehicle_id: jobOld.vehicleNumber
        },
        job_new: {
          account_id: jobNew.accountId,
          job_date: jobNew.jobDate,
          vehicle_id: jobNew.vehicleNumber
        }
      };
      const res = await customAxios.post('/jobs/change', JSON.stringify(data));
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async getDateHaveTrip(officeId, jobDate): Promise<any> {
    try {
      const res = await customAxios.get(`/jobs/copy/filter?office_id=${officeId}&job_date=${jobDate}`);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async copySchedules(officeId: string, jobDateFrom: string, jobDateToArray: Array<any>, listDatePreCheck: Array<any>): Promise<any> {
    try {
      const data = {
        office_id: officeId,
        job_date_from: jobDateFrom,
        job_date_to_array: jobDateToArray,
        job_date_to_array_pre_check: listDatePreCheck
      }
      const res = await customAxios.post('jobs/copy', JSON.stringify(data));
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

}
