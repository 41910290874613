// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import Vue, { createApp, provide, computed, watch, onMounted } from 'vue';
import { createPinia } from 'pinia';
import { useI18n } from 'vue-i18n';

import { useStore, useTranslationStore } from '@/store';
import { setupAxiosInterceptors } from '@/shared/config/axios-interceptor';

import App from './app.vue';
import router from './router';
import { initFortAwesome, initI18N } from './shared/config/config';
import { initBootstrapVue } from './shared/config/config-bootstrap-vue';
import LoginService from '@/services/account/login.service';
import AccountService from '@/services/account/account.service';

import TranslationService from '@/locale/translation.service';
import { KEYS } from './constants';
import _ from 'lodash';
import { CONST } from '@/constants';
import { useJwt } from '@vueuse/integrations/useJwt'
import Navbar from '@/features/core/navbar/navbar.vue';

const pinia = createPinia();

// import entities services here

initBootstrapVue(Vue);

// Vue.configureCompat({
//   MODE: 2,
//   ATTR_FALSE_VALUE: 'suppress-warning',
//   COMPONENT_FUNCTIONAL: 'suppress-warning',
//   COMPONENT_V_MODEL: 'suppress-warning',
//   CONFIG_OPTION_MERGE_STRATS: 'suppress-warning',
//   CONFIG_WHITESPACE: 'suppress-warning',
//   CUSTOM_DIR: 'suppress-warning',
//   GLOBAL_EXTEND: 'suppress-warning',
//   GLOBAL_MOUNT: 'suppress-warning',
//   GLOBAL_PRIVATE_UTIL: 'suppress-warning',
//   GLOBAL_PROTOTYPE: 'suppress-warning',
//   GLOBAL_SET: 'suppress-warning',
//   INSTANCE_ATTRS_CLASS_STYLE: 'suppress-warning',
//   INSTANCE_CHILDREN: 'suppress-warning',
//   INSTANCE_DELETE: 'suppress-warning',
//   INSTANCE_DESTROY: 'suppress-warning',
//   INSTANCE_EVENT_EMITTER: 'suppress-warning',
//   INSTANCE_EVENT_HOOKS: 'suppress-warning',
//   INSTANCE_LISTENERS: 'suppress-warning',
//   INSTANCE_SCOPED_SLOTS: 'suppress-warning',
//   INSTANCE_SET: 'suppress-warning',
//   OPTIONS_BEFORE_DESTROY: 'suppress-warning',
//   OPTIONS_DATA_MERGE: 'suppress-warning',
//   OPTIONS_DESTROYED: 'suppress-warning',
//   RENDER_FUNCTION: 'suppress-warning',
//   WATCH_ARRAY: 'suppress-warning',
// });

const i18n = initI18N();

const app = createApp({
  compatConfig: { MODE: 3 },
  components: { App },
  template: '<App/>',
  setup() {
    //provide('loginService', new LoginService());
    const store = useStore();
    const accountService = new AccountService();
    const i18n = useI18n();
    const translationStore = useTranslationStore();
    const translationService = new TranslationService(i18n);
    const lang = computed(() => store.getLang);
    const { logout } = Navbar.setup()

    const changeLanguage = async (newLanguage: string) => {
      await translationService.refreshTranslation(newLanguage);
      translationStore.setCurrentLanguage(newLanguage);
    };

    provide('currentLanguage', 'ja');
    provide('changeLanguage', changeLanguage);

    watch(
      lang,
      async value => {
        await changeLanguage(value);
      }
    );

    watch(
      () => translationStore.currentLanguage,
      value => {
        translationService.setLocale(value);
      }
    );

    onMounted(async () => {
      await changeLanguage(translationService.getLocalStoreLanguage() ?? 'ja');
    });

    router.beforeResolve(async (to, from, next) => {
      const token = sessionStorage.getItem(KEYS.KEY_API_ID_TOKEN)
      if (to.path !== '/login' && to.path !== '/reset-password' && to.path !== '/forgot-password') {
        if (_.isEmpty(token)) {
          next({ path: '/login' });
          return;
        } else {
          var permissionList = [];
          var screenList = [];
          const payload: any  = useJwt(sessionStorage.getItem(KEYS.KEY_API_ID_TOKEN)).payload;
          if (payload.value?.role_id === CONST.ROLE_NAME_MAPPING.lecip) {
            permissionList = [...CONST.SCREEN_LIST_ADMIN];
            screenList = [...CONST.SCREEN_LIST_ADMIN];
          } else {
            const apis = [accountService.getPermissionsLogin(),store.fetchTimeLogout()];
            try {
              const res = await Promise.all(apis);
              if (res) {
                const permission = res.find(data => data.config.url == "/permissions")
                permissionList = permission.data;
                screenList = [...CONST.SCREEN_LIST];
              }
              screenList = [...CONST.SCREEN_LIST];
            } catch (error) {
              permissionList = [];
            }
          }
          store.setPermissionList(permissionList);
          if (permissionList.length > 0) {
            permissionList = permissionList.sort((a, b) => a.id.localeCompare(b.id));
            permissionList.push({ id: 'id_99', name: 'MapHistory', path: '/map/:history' })
            permissionList.push({ id: 'id_98', name: 'Map', path: '/map/:gps' })
            var permissions = [];
            permissionList?.forEach(data => {
              var permission = screenList.find(screen => screen.id === data.id)
              permission && permissions.push(permission);
            });
            if (!permissions.find(screen => screen.path === to.path)) {
              (from.path !== '/login') && store.setErrorRouter(true);
              next({ path: permissions[0]?.path });
              return;
            }
            useStore().setLoading(false)
          } else {
            logout();
            // next({ path: '/login' });
            return;
          }
        }
      }
      next();
    });

    setupAxiosInterceptors(
      error => {
        const acc = sessionStorage.getItem('account_id') || '';
        const url = error.response?.config?.url;
        const status = error.status || error.response.status;
        if (status && (url === '/refresh-token' || (status === 401 && acc == 'lecip'))){
          store.openSetting(false)
          useStore().setLoading(false);
          sessionStorage.clear();
          sessionStorage.setItem(CONST.TIME_OUT, 'true');
          store.setUserId('');
          router.push({ name: 'Login' });
        }
        return Promise.reject(error);
      },
      error => {
        return Promise.reject(error);
      }
    );

    // provide(
    //   'authenticated',
    //   computed(() => store.authenticated)
    // );
    // provide(
    //   'currentUsername',
    //   computed(() => store.account?.login)
    // );

    provide('translationService', translationService);
    provide('accountService', accountService);
    // Import new entities services here
  },
});

initFortAwesome(app);

app
  .use(router)
  .use(pinia)
  .use(i18n)
  .mount('#app');
