import { customAxios } from "@/shared/config/axios-interceptor";
import axios from 'axios';
import { useStore } from '@/store';

const PATH_MASTER = '/masters';

export default class UserService {
  public async getMasterList(): Promise<any> {
    try {
      const res = await customAxios.get(PATH_MASTER);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async deleteMaster(masterId): Promise<any> {
    try {
      const res = await customAxios.delete(`${PATH_MASTER}/${masterId}`);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async getUrlFile(masterId, fileName): Promise<any> {
    try {
      const res = await customAxios.get(`${PATH_MASTER}/upload/${masterId}/${fileName}`);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async putMasterFile(urlUpload, file): Promise<any> {
    try {
      useStore().setLoading(true);
      const res = await axios.put(urlUpload, file);
      useStore().setLoading(false);
      return res;
    } catch (error) {
      useStore().setLoading(false);
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async validateFile(masterId, fileName, isConvert?): Promise<any> {
    try {
      const res = await customAxios.post(`${PATH_MASTER}/upload/${masterId}/${fileName}/validate${isConvert ? '?p_convert_time_24h=' : ''}`);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async addMaster(masterId, note ,accountId, localUrl): Promise<any> {
    try {
      var data = {
        master_id: masterId,
        master_release_note: note,
        last_editor: accountId,
        local_url: localUrl
      }
      const res = await customAxios.post(PATH_MASTER, data);
      
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async editMaster(masterId, note ,accountId): Promise<any> {
    try {
      var data = {
        master_id: masterId,
        master_release_note: note,
        last_editor: accountId
      }
      const res = await customAxios.put(PATH_MASTER, data);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async exportMaster(masterId): Promise<any> {
    try {
      const res = await customAxios.post(`${PATH_MASTER}/export/${masterId}`);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };
}

