import { customAxios, customAxiosBackground } from "@/shared/config/axios-interceptor";

const PATH_VEHICLE_DETAIL = '/monitors';

export default class VehicleService {
  public async getVehiclesList(officeId, noBackGround): Promise<any> {
    try {
      const res = await (noBackGround ? customAxiosBackground : customAxios).get(`${PATH_VEHICLE_DETAIL}/vehicles?office_id=${officeId}`);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async getDetailVehicle(vehicleId): Promise<any> {
    try {
      const res = await customAxios.get(`${PATH_VEHICLE_DETAIL}/detail-vehicle?vehicle_id=${vehicleId}`);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async getStopList(tripId, noBackGround?): Promise<any> {
    try {
      const data = {
        trip_id: tripId
      }
      const res = await (noBackGround ? customAxiosBackground : customAxios).post(`${PATH_VEHICLE_DETAIL}/stops`,JSON.stringify(data));
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async getShapeList(listTripId, noBackGround, isFilter, callStopAll?): Promise<any> {
    try {
      const data = {
        list_trip_id: isFilter ? [] : listTripId,
        list_pattern_id: isFilter ? listTripId : [],
        get_more_stop_info: callStopAll ? true : false
      }
      const res = await (noBackGround ? customAxiosBackground : customAxios).post(`${PATH_VEHICLE_DETAIL}/shapes`, data);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async getNotificationList(): Promise<any> {
    try {
      const res = await customAxios.get(`notification/active`);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async setSeenNotification(releaseDateTime): Promise<any> {
    try {
      const data = {
        release_date_time: releaseDateTime
      }
      const res = await customAxios.put(`notification/check`, data);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async fetchAllRoute(officeId: string, noBackGround?): Promise<any> {
    try {
      const data = {
        office_id: officeId
      }
      const res = await (noBackGround ? customAxiosBackground : customAxios).post(`${PATH_VEHICLE_DETAIL}/routes`, data);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };
}

