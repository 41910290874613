import { defineComponent, onBeforeUnmount, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Datepicker from "vuejs3-datepicker";
import { useDateUtils } from '@/shared/composables';

export default defineComponent({
  compatConfig: { MODE: 3 },
  components: {
    Datepicker: Datepicker,
  },
  props: {
    date: Date
  },
  setup(props, { emit }) {

    const newDate = ref(props.date);
    let openDate = ref(props.date);

    // check the change of props.date to update newDate
    watch(() => props.date, (newVal) => {
        newDate.value = newVal;
      }
    );

    // check the change of newDate to update parent component's date
    watch(newDate, () => {
      emit('update-date', newDate);
    });

    // if click outside of date picker then reassign open date
    function clickOutsideDatePicker(e: MouseEvent) {
      let target = e.target as Element;
      let outSide = true;
      while(target.parentNode && target.parentNode.nodeName.toLowerCase() != 'body') {
        if (target.classList.contains('vuejs3-datepicker')) {
          outSide = false;
          break;
        }
        target = target.parentNode as Element;
      }
      if (outSide) {
        openDate.value = new Date(props.date || new Date());
      }
    }

    document.addEventListener('click', clickOutsideDatePicker);

    onBeforeUnmount(() => {
      document.removeEventListener('click', clickOutsideDatePicker);
    });

    return {
      t$: useI18n().t,
      newDate,
      openDate
    };
  },
});
