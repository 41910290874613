import { mkConfig, generateCsv, download } from 'export-to-csv';
import { useDateFormat } from './date-format';
export const useParseFile = () => {
  const parseCsv = (data: Array<any>, fileName: string) => {
    if(data.length > 0){
      const csvConfig = mkConfig({ useKeysAsHeaders: true,filename: fileName});
      const csv = generateCsv(csvConfig)(data);
      download(csvConfig)(csv);
    }
  };

  return {
    parseCsv
  }
};
