const OperatorSummary = () => import('@/features/report/operator-summary.vue');
const OperatorDay = () => import('@/features/report/operator-day.vue');
const OperatorMonth = () => import('@/features/report/operator-month.vue');

export default [
  {
    path: '/operator/summary',
    name: 'OperatorSummary',
    component: OperatorSummary,
  },
  {
    path: '/operator/day',
    name: 'OperatorDay',
    component: OperatorDay,
  },
  {
    path: '/operator/month',
    name: 'OperatorMonth',
    component: OperatorMonth,
  },
];
