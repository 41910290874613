import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "box-alert d-flex justify-content-center"
}
const _hoisted_2 = { class: "material-icons-outlined animated" }
const _hoisted_3 = { class: "alert__content-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass('d-flex box-alert__content alert-' + _ctx.type)
        }, [
          _createElementVNode("i", _hoisted_2, _toDisplayString(_ctx.icon), 1 /* TEXT */),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.message), 1 /* TEXT */)
        ], 2 /* CLASS */)
      ]))
    : _createCommentVNode("v-if", true)
}