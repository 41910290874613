import dayjs from "dayjs";
import _ from "lodash";
import { CONST } from '@/constants';

export const useDateUtils = () => {

  const compareDate = (date1: Date | string, date2: Date | string, format="YYYY/MM/DD HH:mm:ss") : number => {
    if (_.isNil(date1) && _.isNil(date2)) {
      return 0;
    }
    if (_.isNil(date1) && !_.isNil(date2)) {
      return -1;
    }
    if (!_.isNil(date1) && _.isNil(date2)) {
      return 1;
    }
    let firstDate = null;
    let secondDate = null;
    if (_.isString(date1)) {
      firstDate =  _.isEmpty(date1) ? null : dayjs(_.toString(date1), format);
    } else {
      firstDate = dayjs(date1)
    }
    if (_.isString(date2)) {
      secondDate =  _.isEmpty(date2) ? null : dayjs(_.toString(date2), format);
    } else {
      secondDate = dayjs(date2)
    }
    if (_.isNil(firstDate) && _.isNil(secondDate)) {
      return 0;
    }
    if (_.isNil(firstDate) && !_.isNil(secondDate)) {
      return -1;
    }
    if (!_.isNil(firstDate) && _.isNil(secondDate)) {
      return 1;
    }
    if (firstDate.year() < secondDate.year()) {
      return -1;
    } else if (firstDate.year() > secondDate.year()) {
      return 1;
    }
    if (firstDate.month() < secondDate.month()) {
      return -1;
    } else if (firstDate.month() > secondDate.month()) {
      return 1;
    }
    if (firstDate.date() < secondDate.date()) {
      return -1;
    } else if (firstDate.date() > secondDate.date()) {
      return 1;
    }
    if (firstDate.hour() < secondDate.hour()) {
      return -1;
    } else if (firstDate.hour() > secondDate.hour()) {
      return 1;
    }
    if (firstDate.minute() < secondDate.minute()) {
      return -1;
    } else if (firstDate.minute() > secondDate.minute()) {
      return 1;
    }
    return 0;
  }

  const convertTimeToDecimal = timeString => {
    if(timeString){
      const [hours, minutes, seconds] = timeString.split(':').map(Number);
      const totalSeconds = hours + minutes / 60 + seconds / 3600;
      return totalSeconds;
    }
  };

  /**
   * Get Japan date time
   * @param dateTime Local Date time
   * @returns Japan date time.
   */
  const getJPDate = (date?: Date|string|number) => {
    let x = new Date();
    if(date) {
      x = new Date(date);
    }
    const convertedDate = x.toLocaleString('ja-JP', {timeZone: 'Japan'});
    return new Date(convertedDate);
  }

  /**
   * Get Japan date time
   * @param date Local Date time
   * @returns format 04h-27h
   */
  const handleConvertTime = (date) => {
    const hour = dayjs(date).get('hour');
    if (hour < CONST.TIMEEND) {
      return new Date(dayjs(date).subtract(1,'day').format('YYYY-MM-DD'));
    } else {
      return date;
    }
  }

  return {
    compareDate,
    convertTimeToDecimal,
    getJPDate,
    handleConvertTime
  }
};