// Add new constants here
export const KEYS = {
  KEY_API_ACCESS_TOKEN: 'api_token',
  KEY_API_REFRESH_TOKEN: 'refresh_token',
  KEY_API_ID_TOKEN: 'id_token',
  KEY_API_USER_OFFICE_ID: 'officeIdUser',
  KEY_API_USER_OFFICE_NAME: 'officeUser',
  KEY_API_USER_ID: 'user_id',
}

const ACCOUNT = {
  ACCOUNT_ID: 'account_id',
  USER_ID: 'user_id',
  EMAIL_FG: 'email_fg',
  ROLE_ID: 'role_id',
  PERMISSIONS: 'permissions',
  MSG_LOCK_ACCOUNT: 'MSG0016',
  MSG0003: 'MSG0003',
  MSG0007: 'MSG0007',
  MSG0012: 'MSG0012',
  MSG0017: 'MSG0017',
  MSG0018: 'MSG0018',
  MSG0021: 'MSG0021',
  EXIST_ACCOUNT_ID: 'MSG0019'
}

const SCREEN_LIST = [
  { id: 'id_01', name: 'ListVehicle', path: '/monitoring/list' },
  { id: 'id_02', name: 'HistoryConfirm', path: '/history-confirm' },
  { id: 'id_03', name: 'MasterManager', path: '/master-manager' },
  { id: 'id_04', name: 'ScheduleDispatcher', path: '/schedule-dispatcher' },
  { id: 'id_05', name: 'EditRoute', path: '/edit-route' },
  { id: 'id_06', name: 'BusDevice', path: '/list-bus-device', },
  { id: 'id_07', name: 'UserManager', path: '/user-manager' },
  { id: 'id_08', name: 'UserNotification', path: '/user-notification' },
  { id: 'id_98', name: 'Map', path: '/map/:gps'},
  { id: 'id_99', name: 'MapHistory', path: '/map/:history' }
]

const SCREEN_LIST_ADMIN = [
  { id: 'id_09', name: 'AccountManager', path: '/account-manager' },
  { id: 'id_10', name: 'NotificationSetting', path: '/notification-setting' }
]

const CLASS_NAME_SCREEN = {
  id_01: "bg-nav2",
  id_02: "bg-nav3",
  id_03: "bg-nav1",
  id_04: "bg-nav1",
  id_05: "bg-nav1",
  id_06: "bg-nav1",
  id_07: "bg-nav1",
  id_08: "bg-nav4",
  id_09: "bg-nav1",
  id_10: "bg-nav1",
};

const FIRST_PERCENT = 99;
const PERCENT_JUMP = 9;
const TIME_OUT = 'timeOut';
const ERROR_ROUTER = 'errorRouter';

const ROLE_NAME_MAPPING = {
  general: "0",
  manager: "1",
  admin: "2",
  root: "3",
  lecip: "4"
}

const FILE_NAMES = [
  { name: 'agency.txt', status: false },
  { name: 'agency_jp.txt', status: false },
  { name: 'calendar.txt', status: false },
  { name: 'calendar_dates.txt', status: false },
  { name: 'fare_attributes.txt', status: false },
  { name: 'fare_rules.txt', status: false },
  { name: 'feed_info.txt', status: false },
  { name: 'frequencies.txt', status: false },
  { name: 'office_jp.txt', status: false },
  { name: 'pattern_jp.txt', status: false },
  { name: 'routes.txt', status: false },
  { name: 'shapes.txt', status: false },
  { name: 'stop_times.txt', status: false },
  { name: 'stops.txt', status: false },
  { name: 'transfers.txt', status: false },
  { name: 'translations.txt', status: false },
  { name: 'trips.txt', status: false }
]
const USER = {
  EXIST_USER_ID: "MSG0020"
}

const MESSAGE_ERROR = {
  MSG0062: 'MSG0062',
  MSG0066: 'MSG0066',
  MSG0069: 'MSG0069',
  MSG0069_1: 'MSG0069_1',
  MSG0069_2: 'MSG0069_2',
  MSG0069_3: 'MSG0069_3',
  MSG0073: 'MSG0073',
  MSG0072: 'MSG0072',
  MSG0071: 'MSG0071',
  MSG0104: 'MSG0104',
  MSG0075: 'MSG0075',
  MSG0076: 'MSG0076',
  MSG0079: 'MSG0079'
}

const MESSAGE_SUCCESS = {
  MSG0068: 'MSG0068'

}

const PATH_SHOW_BTN_GROUP = [
  '/schedule-dispatcher',
  '/history-confirm'
];

const DAY_NAMES = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
const ICON_BUS_STOP = {
  ICON_ENABLED_PATH: 'content/images/icon-location-enabled.svg',
  ICON_DISABLED_PATH: 'content/images/icon-location-disabled.svg',
  HEIGHT: 35,
  WIDTH: 35
}

const ICON_BUS = {
  OFF_SERVICE_PATH: 'content/images/bus-icon-gray.png',
  STANDY_PATH: 'content/images/bus-icon-green.png',
  EARLY_PATH: 'content/images/bus-icon-light-green.png',
  ON_TIME_PATH: 'content/images/bus-icon-blue.png',
  LATE_5_PATH: 'content/images/bus-icon-yellow.png',
  LATE_10_PATH: 'content/images/bus-icon-red.png',
  HEIGHT: 35,
  WIDTH: 35,
  LARGE_HEIGHT: 60,
  LARGE_WIDTH: 60,
  STYLE_OFF_SERVICE: '#9d9d9d',
  STYLE_STANDY_PATH: '#006644',
  STYLE_EARLY_PATH: '#ABF5D1',
  STYLE_ON_TIME_PATH: '#6ba7ea',
  STYLE_LATE_5_PATH: '#FFC400',
  STYLE_LATE_10_PATH: '#ff6c6c',
}

const ICON_BUS_HISTORY = {
  EARLY_PATH: 'content/images/bus-icon-light-green.png',
  ON_TIME_PATH: 'content/images/bus-icon-blue.png',
  LATE_5_PATH: 'content/images/bus-icon-yellow.png',
  LATE_10_PATH: 'content/images/bus-icon-red.png',
}

const ICON_BUS_GPS = {
  BUS: 'content/images/marker.png',
  MARKER: 'content/images/icon-1.png',
  MARKER_SELECTED: 'content/images/icon-2.png'
}

const MAP = {
  ZOOM_DEFAULT: 11,
  ZOOM_SELECTED: 15,
  POSITION_CENTER_DEFAULT: { lat: 35.62982, lng: 139.794242 }
}

const REGEX_MSG_CODE = /^(msg|MSG)\d{4}$/;

const REGEX_EMAIL = /^[\w]+(?:[.-]?[\w]+)*@[a-zA-Z\d]+(?:[.-]?[a-zA-Z\d]+)*\.[a-zA-Z]{2,}$/;


const STATUS_BUS = {
  LOST_CONNECTION: '0',
  OFF_SERVICE: '1',
  ON_SERVICE: '2',
  STANDBY: '3',
  OFFLINE: '4',
  POWER_OFF: '5'
};

const LOGIN_NAME = 'Login';

const Z_INDEX_MAP = {
  Z_INDEX_BUS_TOP: 5,
  Z_INDEX_VEHICLE : 100,
  Z_INDEX_VEHICLE_SELECT : 101,
  Z_INDEX_SHAPE_POINT : 999
}

const LOAD_MAP_TIME = 30000

const EVENT_HIDDEN = ['系統開始', '系統終了', 'System shutdown' , 'System start']

const ROOT_NAME = {
  ROOT: "root",
  LECIP: "LECIP"
}

const TABLE_NAME = {
  ROUTES: "routes",
  STOPS: "stops",
  PATTERN: "pattern_jp",
  TRIPS: "trips",
  STOP_TIMES: "stop_times",
}
const TIME_BUS_NOT_ON_SERVICE = -999999999
const TIME_BUS_OFFLINE = -999999998
const TIMEEND = 4;

export const CONST = {
  SCREEN_LIST: SCREEN_LIST,
  CLASS_NAME_SCREEN: CLASS_NAME_SCREEN,
  FIRST_PERCENT: FIRST_PERCENT,
  PERCENT_JUMP: PERCENT_JUMP,
  ACCOUNT: ACCOUNT,
  ROLE_NAME_MAPPING,
  FILE_NAMES,
  USER,
  TIME_OUT,
  ERROR_ROUTER,
  MESSAGE_ERROR,
  PATH_SHOW_BTN_GROUP: PATH_SHOW_BTN_GROUP,
  DAY_NAMES: DAY_NAMES,
  SCREEN_LIST_ADMIN,
  ICON_BUS_STOP: ICON_BUS_STOP,
  MAP: MAP,
  REGEX_MSG_CODE: REGEX_MSG_CODE,
  STATUS_BUS: STATUS_BUS,
  ICON_BUS: ICON_BUS,
  REGEX_EMAIL: REGEX_EMAIL,
  LOGIN_NAME,
  ICON_BUS_HISTORY: ICON_BUS_HISTORY,
  Z_INDEX_MAP,
  LOAD_MAP_TIME,
  ROOT_NAME,
  MESSAGE_SUCCESS,
  TABLE_NAME,
  EVENT_HIDDEN,
  TIME_BUS_NOT_ON_SERVICE,
  TIME_BUS_OFFLINE,
  TIMEEND,
  ICON_BUS_GPS
}
