import { useStore } from '@/store';
import { defineStore } from 'pinia';
import MasterService from '@/services/master/master.service';
import { HttpStatusCode } from 'axios';

const ASC = true;
const DESC = false;
const DEFAULT_ERR_CODE = 'msg0000';
const masterService = new MasterService();
export interface MasterStateStorable {
  listMasterData: Array<any>;
  filtered: {
    office: string,
    role: string,
    display: string
  };
  sortStatus: {
    prop: string,
    direction: Boolean
  };
  selectedMaster: any;
}

export const defaultMasterState: MasterStateStorable = {
  listMasterData: [],
  filtered: {
    office: '',
    role: '',
    display: ''
  },
  sortStatus: {
    prop: 'startDate',
    direction: DESC
  },
  selectedMaster: null
}

export const useStoreMaster = defineStore('masterStore', {
  state: (): MasterStateStorable => ({ ...defaultMasterState }),

  actions: {
    resetSortStatus() {
      this.sortStatus = { ...defaultMasterState.sortStatus };
    },
    setNewMaster(payload) {
      this.listMasterData.push(payload)
    },
    editMaster(payload){
      const index = this.listMasterData.findIndex(data => data.id === payload.id)
      if(index !== 1){
        this.listMasterData.splice(index,1,payload)
      }
    },
    deleteMaster: async function () {
      try {
        let res = await masterService.deleteMaster(this.selectedMaster.masterId);
        useStore().setLoading(false);
        return {
          error: !(res && res.status === HttpStatusCode.Ok),
          errorCode: res.data && res.data.message ? res.data.message : DEFAULT_ERR_CODE
        };
      } catch (error) {
        return {
          error: true,
          errorCode: error.data && error.data.message ? error.data.message : DEFAULT_ERR_CODE
        }
      }
    },

    sort: function (prop) {
      var stringProp = prop + '';
      this.sortStatus.direction = this.sortStatus.prop === stringProp ? !this.sortStatus.direction : ASC;
      this.sortStatus.prop = stringProp;
    },

    selectMaster: function (master) {
      this.selectedMaster = this.selectedMaster !== master ? master : null;
    },

    getMasterList: async function () {
      try {
        const res = await masterService.getMasterList();
        if (res && res.status === HttpStatusCode.Ok) {
          this.listMasterData = res.data;
          this.listMasterData = res.data.map(function (master) {
            return {
              masterId: master.master_id,
              status: master.master_status_apply,
              startDate: master.master_start_date,
              endDate: master.master_end_date,
              notesRelease: master.master_release_note,
              finalEditor: master.last_editor,
              lastEditedDate: master.last_edited_date,
              localUrl: master.local_url,
            }
          });
          useStore().setLoading(false);
        }
      } catch (error) {
        this.listMasterData = [];
      }
    },
  },
  getters: {
    getDataMaster(){
      return this.listMasterData
    },
    getListMasterFiltered: (state) => {
      let masters = state.listMasterData;
      return [...masters].sort((u1, u2) => (state.sortStatus.direction ? 1 : -1) * `${u1[state.sortStatus.prop]}`.localeCompare(`${u2[state.sortStatus.prop]}`));
    }
  }
});
