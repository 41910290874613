const PATH_ACCOUNT = '/accounts';
import { customAxios } from '@/shared/config/axios-interceptor';
import { useDateFormat } from '@/shared/composables';
import { AccountStore } from '@/store';
import _ from 'lodash';
const typeDate = "YYYY-MM-DD"

export default class AccountService {
  public async getListAccount(): Promise<any> {
    try {
      const res = await customAxios.get(PATH_ACCOUNT);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async deleteAccount(accountId: string): Promise<any> {
    try {
      const res = await customAxios.delete(`${PATH_ACCOUNT}/${accountId}`);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async getPermissions(): Promise<any> {
    try {
      const res = await customAxios.get(`${PATH_ACCOUNT}/permissions`);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  }
  public async addAccount(params: {accountID: string, agencyName: string,rootMailAddress: string,startDate: any, endDate: string, rootPassword: string, permissions: object  }): Promise<any> {
    try {
      // list params
      const data = {
        account_id: params.accountID,
        agency_name: params.agencyName,
        email: params.rootMailAddress,
        start_date: useDateFormat().formatDate(params.startDate,typeDate),
        end_date: useDateFormat().formatDate(params.endDate,typeDate),
        password: params.rootPassword,
        permissions: params.permissions,
      };
      const res = await customAxios.post(PATH_ACCOUNT, JSON.stringify(data));
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  }
  public async editAccount(params: {accountID: string, agencyName: string,rootMailAddress: string,startDate: any, endDate: string, permissions: object  }): Promise<any> {
    try {
      // list params
      const data = {
        account_id: params.accountID,
        agency_name: params.agencyName,
        email: params.rootMailAddress,
        start_date: useDateFormat().formatDate(params.startDate,typeDate),
        end_date: useDateFormat().formatDate(params.endDate,typeDate),
        permissions: params.permissions,
      };
      const res = await customAxios.put(PATH_ACCOUNT, JSON.stringify(data));
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };
  public async getDetailAccount(params: string): Promise<any> {
    try{
      const res = await customAxios.get(`${PATH_ACCOUNT}/${params}`);
      return res
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  }

  public async getAgency() {
    try{
      const res = await customAxios.get(`${PATH_ACCOUNT}/agency`);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  }

  public async getPermissionsLogin(): Promise<any> {
    try {
      var path = `/permissions`;
      const res = await customAxios.get(path)
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  }
}
