import { defineComponent, ref, watch, inject, computed, onMounted, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import SettingService from '@/services/setting/setting.service';
import { useStore, useRoleUserStore } from '@/store';
import { KEYS } from '@/constants';

export default defineComponent({
  compatConfig: { MODE: 3 },
  setup() {
    const store = useStore();
    const roleUserStore = useRoleUserStore();
    const settingService = inject('settingService', () => new SettingService(), true);
    const isOpenSetting = computed(() => store.isOpenSetting);
    const settingModel = reactive({
      lang: 'ja',
      timeLogout: ''
    });
    const hasChangedRoleUser = computed(() => roleUserStore.getHasChanged);

    const closeSetting = () => {
      store.openSetting(false)
    }

    const getDetailSetting = async () => {
      try {
        const res = await settingService.getDetailSetting();
        if(res.status === 200) {
          settingModel.lang = res.data.language === "jp" ? "ja" : res.data.language;
          localStorage.setItem('currentLanguage',res.data.language === "jp" ? "ja" : res.data.language);
          store.setLang(res.data.language === "jp" ? "ja" : res.data.language)
          settingModel.timeLogout = res.data.autoLogoutTime;
          useStore().setLoading(false);
        }
      } catch (error) {

      }
    }

    const handleSetting = async (event) => {
      store.setLang(settingModel.lang)
      try {
        if (hasChangedRoleUser.value) {
          roleUserStore.setShowWanningModal(true);
          roleUserStore.setActionAcceptWarn(() => {event?.target?.click()});
        } else {
          await settingService.saveSetting(settingModel);
          store.setTime(settingModel.timeLogout);
          closeSetting();
          window.location.href = window.location.href;
        }
      } catch (error) {
        // todo
      }
    }

    onMounted(async () => {
        await getDetailSetting();
    });

    return {
      t$: useI18n().t,
      store: store,
      isOpenSetting,
      closeSetting,
      settingModel,
      handleSetting,
    };
  },
});