import { defineComponent, ref, computed, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Modal from '@/features/common/modal/basic-modal.vue';
import { useHistoryStore } from '@/store';
import { KEYS } from '@/constants';
import { useDateFormat, useDateUtils } from '@/shared/composables';
import DatePicker from '@/features/common/datePicker/date-picker.vue';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import _ from 'lodash';
import Alert from '@/features/common/alert/alert.vue';
import dayjs from 'dayjs';
import { useStore } from '@/store';

export default defineComponent({
  compatConfig: { MODE: 3 },
  components: { Modal, DatePicker, Alert },
  setup() {
    const store = useHistoryStore();
    const useI18nT = useI18n().t;
    const showModal = ref({
      exportByDay: false,
      exportByMonth: false
    });
    const msgExistErr = ref('');

    const user_id = ref(sessionStorage.getItem(KEYS.KEY_API_USER_ID));
    const offices = computed(() => store.getListOffice);
    const userOfficeId = computed(() => store.getOfficeOfUser);

    watch(userOfficeId, (newVal) => {
      exportModel.filterCondition = newVal;
      exportMonthModel.filterCondition = newVal;
      exportModelDayClone.filterCondition = newVal;
      exportModelMonthClone.filterCondition = newVal;
    })
    const exportModel = reactive({
      exportDate: store.selectedDate,
      filterCondition: '',
    });

    const exportMonthModel = reactive({
      exportDate: store.selectedMonth,
      filterCondition: '',
    });

    const alertModel = reactive({
      isVisible: false,
      msg: '',
      type: '',
      icon:''
    });

    let exportModelDayClone = reactive(_.cloneDeep(exportModel));
    let exportModelMonthClone = reactive(_.cloneDeep(exportMonthModel));

    const rulesExportDate = computed(() => ({
      filterCondition: {
        required: helpers.withMessage(() => useI18nT("error.msg0053"), required),
      }
    }))
    const vExportDate$ = useVuelidate(rulesExportDate, exportModel);


    const rulesExportMonth = computed(() => ({
      filterCondition: {
        required: helpers.withMessage(() => useI18nT("error.msg0053"), required),
      }
    }))
    const vExportMonth$ = useVuelidate(rulesExportMonth, exportMonthModel);

    function openModal(modalId) {
      vExportDate$.value.$reset();
      vExportMonth$.value.$reset();
      exportModel['filterCondition'] = exportModelDayClone['filterCondition'];
      exportModel['exportDate'] = store.selectedDate;
      exportMonthModel['filterCondition'] = exportModelMonthClone['filterCondition'];
      exportMonthModel['exportDate'] = store.selectedMonth;
      showModal.value[modalId] = true;
      if (modalId === 'exportByDay' || modalId === 'exportByMonth') {
        alertModel.isVisible = false;
      }
    }

    function closeModal(modalId) {
      showModal.value[modalId] = false;
      msgExistErr.value = '';
    }

    const handleSelectExportDate = selectedDate => {
      exportModel.exportDate = selectedDate.value;
    };

    const handleSelectExportMonthDate = selectedDate => {
      exportMonthModel.exportDate = selectedDate.value;
    };

    const handleExportFileMonth = async () => {
      vExportMonth$.value.$validate();
      if (vExportMonth$.value.$error) {
        return;
      }
      try {
        const res = await store.getFileExportMonth(exportMonthModel);
        if(res.status === 200){
          const fileUrl = res.data.url_download;
          const link = document.createElement('a');
          link.href = fileUrl;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          closeModal('exportByMonth');
          msgExistErr.value = '';
          useStore().setLoading(false);
        }
      } catch (error) {
        // TODO:
        msgExistErr.value = useI18nT(`error.${error.data.message.toLowerCase()}`);
      }
    }

    const handleExportFileDay = async () => {
      vExportDate$.value.$validate();
      if (vExportDate$.value.$error) {
        return;
      }
      try {
        const res = await store.getFileExportDay(exportModel);
        const fileUrl = res.data.url_download;
        const link = document.createElement('a');
        link.href = fileUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        closeModal('exportByDay');
        msgExistErr.value = '';
        useStore().setLoading(false);
      } catch (error) {
        // TODO:
        msgExistErr.value = useI18nT(`error.${error.data.message.toLowerCase()}`);
      }
    }

    const date = dayjs().subtract(13,'month').format("YYYY-MM-DD")
    let disableDateFrom = new Date(date);
    disableDateFrom = useDateUtils().handleConvertTime(disableDateFrom);
    disableDateFrom.setHours(0);
    disableDateFrom.setMinutes(0);
    disableDateFrom.setSeconds(0);
    let disableDateTo = new Date(new Date().setDate(new Date().getDate() - 1));
    disableDateTo = useDateUtils().handleConvertTime(disableDateTo);
    disableDateTo.setHours(23);
    disableDateTo.setMinutes(59);
    disableDateTo.setSeconds(59);

    return {
      t$: useI18n().t,
      showModal,
      openModal,
      closeModal,
      user_id,
      offices,
      userOfficeId,
      exportModel,
      handleSelectExportDate,
      useDateFormat,
      exportMonthModel,
      handleSelectExportMonthDate,
      handleExportFileMonth,
      vExportDate$,
      vExportMonth$,
      handleExportFileDay,
      msgExistErr,
      alertModel,
      useDateUtils,
      disableDateFrom,
      disableDateTo
    };
  },
});
