import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import BasicModal from '@/features/common/modal/basic-modal.vue';
import ErrorModal from '@/features/common/modal/ErrorModal/error-modal.vue';
import router from '@/router';
import axios, { HttpStatusCode } from 'axios';
import { useStore, useTranslationStore } from '@/store';

export default defineComponent({
  compatConfig: { MODE: 3 },
  emits: ['close'],
  components: {
    BasicModal, ErrorModal
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {emit}) {
    const translationStore = useTranslationStore();
    const useI18nT = useI18n().t;
    const version = SOFT_VERSION;
    const errMsg = ref('');
    
    async function downloadManual() {
      let error = false;
      const currentLanguage = translationStore.currentLanguage;
      let fileName = useI18nT(`help.manual.${router.currentRoute.value.path}`);
      let manualApilUrl = `${SERVER_API_URL}/help?language=${currentLanguage}&filename=${fileName}`;
      let manualUrl = '';
      await axios.get(manualApilUrl)
        .then(res => {
          if (res.status !== HttpStatusCode.Ok) {
            error = true;
          } else {
            manualUrl = res.data.url_download;
            useStore().setLoading(false);
          }
        })
        .catch(e => {
          error = true;
        });
      if (!error && manualUrl) {
        window.open(manualUrl, '_blank');
      } else {
        errMsg.value = useI18nT('error.msg0106');
      }
      emit('close');
    }

    return {
      t$: useI18n().t,
      version: version,
      errMsg: errMsg,
      downloadManual: downloadManual
    };
  },
});