import { createRouter as createVueRouter, createWebHistory } from 'vue-router';

const Home = () => import('@/features/core/home/home.vue');
const Error = () => import('@/features/core/error/error.vue');
const Login = () => import('@/features/login/login.vue');
const ForgotPassword = () => import('@/features/login/forgot-password.vue');
const ResetPassword = () => import('@/features/login/reset-password.vue');
import report from '@/router/report';
import maintain from '@/router/maintain';
import monitoring from '@/router/monitoring';

export const createRouter = () =>
  createVueRouter({
    history: createWebHistory(),
    routes: [
      {
        path: '/',
        name: 'Home',
        component: Home,
      },
      {
        path: '/login',
        name: 'Login',
        component: Login,
      },
      {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
      },
      {
        path: '/reset-password',
        name: 'ResetPassword',
        props: true,
        component: ResetPassword,
      },
      
      {
        path: '/forbidden',
        name: 'Forbidden',
        component: Error,
        meta: { error403: true},
      },
      {
        path: '/not-found',
        name: 'NotFound',
        component: Error,
        meta: { error404: true},
      },
      ...monitoring,
      ...report,
      ...maintain,
    ],
  });

const router = createRouter();

router.beforeResolve(async (to, from, next) => {
  if (!to.matched.length) {
    next({ path: '/not-found' });
    return;
  }
  next();
});

export default router;
